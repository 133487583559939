import { Component, OnInit } from "@angular/core";
import { TrackingService } from "src/app/services/tracking.service";
import { Tracking } from "src/app/models/tracking";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-trackingdetail",
  templateUrl: "./trackingdetail.component.html",
  styleUrls: ["./trackingdetail.component.css"]
})
export class TrackingdetailComponent implements OnInit {
  tracking = {};
  ID;
  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();

    this.route.params.subscribe(params => {
      this.ID = params["id"];
    });

    this.trackingService.GetTracking(this.ID).subscribe(response => {
      if (response != null) {
        this.tracking = response;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        alert("Invalid Tracking Number");
      }
    });
  }
}
