import { Component, OnInit } from "@angular/core";
import { TrackingService } from "src/app/services/tracking.service";
import { Tracking } from "src/app/models/tracking";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  tracking = {};
  constructor(
    private trackingService: TrackingService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {}

  GetTracking(TrackingNo) {
    if (TrackingNo != null) {
      if (TrackingNo != "") {
        this.spinner.show();
        document.getElementById("id01").style.display = "block";
        this.trackingService.GetTracking(TrackingNo).subscribe(response => {
          if (response != null) {
            this.tracking = response;
            this.spinner.hide();
          } else {
            this.spinner.hide();
            document.getElementById("id01").style.display = "none";
            alert("Invalid Tracking Number");
          }
        });
      } else {
        alert("Please enter proper Tracking Number");
      }
    } else {
      alert("Please enter proper Tracking Number");
    }
  }
}
