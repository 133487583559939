import { Injectable } from "@angular/core";
import { Global } from "src/app/common/global";
import { Tracking } from "src/app/models/tracking";
import { Http, URLSearchParams, RequestOptions, Response } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { error } from "util";
import { HttpClient } from "@angular/common/http";
import { Email } from "src/app/models/Email";
import { MultipleTracking } from "src/app/models/multipletracking";
import { SendEmail } from "src/app/models/sendemail";

@Injectable()
export class TrackingService {
  private global: Global = new Global();
  
  constructor(private http: Http) {}

  GetTracking(TrackingNo): Observable<Tracking[]> {
    return this.http.get(this.global.baseUrl + "Tracking/" + TrackingNo).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  //GetMultiTracking(TrackingNos): Observable<Tracking[]> {
  GetMultiTracking(tracking: MultipleTracking, TrackingNos) {
    let params: URLSearchParams = new URLSearchParams();
    params.set("MultiTrackingNo", TrackingNos);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http.post(this.global.baseUrl + "MultipleTracking", params).map(
      (response: Response) => {
        var result = response.json();
        return result;
      },
      error => this.handleError(error)
    );
  }

  SendEmail(sendemail: SendEmail) {
    let params: URLSearchParams = new URLSearchParams();
    params.set("FirstName", sendemail.FirstName);
    params.set("LastName", sendemail.LastName);
    params.set("CompanyName", sendemail.CompanyName);
    params.set("Address", sendemail.Address);
    params.set("City", sendemail.City);
    params.set("ZipCode", sendemail.ZipCode);
    params.set("State", sendemail.State);
    params.set("Country", sendemail.Country);
    params.set("EmailID", sendemail.EmailID);
    params.set("MobileNo", sendemail.MobileNo);
    params.set("Requirement", sendemail.Requirement);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http.post(this.global.baseUrl + "SendEmail", params).map(
      a => {
        console.log("Response:" + Response);
        console.log("Status:" + a.status);
        console.log("StatusText:" + a.statusText);
        console.log("Error:" + error);
        return a.status == 200;
        //return Response;
      },
      err => this.handleError(err)
    );
  }

  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || "";
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
