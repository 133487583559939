import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { HomeComponent } from "./layout/home/home.component";
import { AboutComponent } from "./layout/about/about.component";
import { ServiceComponent } from "./layout/service/service.component";
import { InquiryComponent } from "./layout/inquiry/inquiry.component";
import { ContactComponent } from "./layout/contact/contact.component";
import { MultipletrackingComponent } from "./layout/multipletracking/multipletracking.component";
import { TrackingdetailComponent } from "./layout/trackingdetail/trackingdetail.component";
import { TrackingService } from "src/app/services/tracking.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ServiceComponent,
    InquiryComponent,
    ContactComponent,
    MultipletrackingComponent,
    TrackingdetailComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgxSpinnerModule,
    RouterModule.forRoot(
      [
        { path: "", component: HomeComponent },
        { path: "about", component: AboutComponent },
        { path: "service", component: ServiceComponent },
        { path: "inquiry", component: InquiryComponent },
        { path: "contact", component: ContactComponent },
        { path: "multipletracking", component: MultipletrackingComponent },
        { path: "trackingdetail/:id", component: TrackingdetailComponent }
      ]
      //{ useHash: true }
    )
  ],
  providers: [
    TrackingService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
