import { Component, OnInit } from "@angular/core";
import { TrackingService } from "src/app/services/tracking.service";
import { Tracking } from "src/app/models/tracking";
import { NgxSpinnerService } from "ngx-spinner";
import { MultipleTracking } from "src/app/models/multipletracking";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";

@Component({
  selector: "app-multipletracking",
  templateUrl: "./multipletracking.component.html",
  styleUrls: ["./multipletracking.component.css"]
})
export class MultipletrackingComponent implements OnInit {
  tracking = {};
  public multipletracking = new MultipleTracking();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private trackingService: TrackingService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {}
  GoTop() {
    const mainDiv = document.getElementById("mainDIV");
    mainDiv.scrollTop = 0;
  }
  
  GetMultiTracking(TrackingNos) {
    if (TrackingNos != null) {
      if (TrackingNos != "") {
        var objDiv = document.getElementById("mainDIV");
        objDiv.scrollTop = objDiv.scrollHeight;
        this.spinner.show();
        this.trackingService
          .GetMultiTracking(this.multipletracking, TrackingNos)
          .subscribe(response => {
            if (response != null) {
              this.tracking = response;
              this.spinner.hide();
            } else {
              this.spinner.hide();
              alert("Invalid Tracking Number");
            }
          });
      } else {
        alert("Please enter proper Tracking Number");
      }
    } else {
      alert("Please enter proper Tracking Number");
    }
  }
}
